import React, { useState, useEffect } from "react"
import { 
   Box, 
   Link, 
   Text, 
   HStack,
   Image,
   Button,
   Center,
   Spinner,
   Switch,
   Tooltip,
   Skeleton,
   Select,
   Spacer,
   Wrap,
   WrapItem,
   AspectRatio
} from "@chakra-ui/react"
import { Link as GatsbyLink } from 'gatsby'
import { 
   BiSort
} from "react-icons/bi"
import axios  from 'axios'
import Layout from '../components/layout.js';
import {
   getWindowParam,
   num2ja,
   Midasi2,
   Midasi3,
   RadioButtonsC5,
   Pagenavi2
} from '../util/functions.js';


const ChannelList = (pageprops) => {
   const [params,setParams] = useState({
      keyword:"",
      creator:"全て",
      genre:"全て",
      duration:"全期間",
      viewOrRegist:"再生回数",
      vtu:0,
      vtd:0,
      rtu:0,
      rtd:0,
      page:1,
      id:""
   });
   const [pager,setPager] = useState({
      page:1,
      isFirstpage:true,
      isEndpage:false
   })
   const [isaddkeyword,setisaddkeyword] = useState(false);

   const [dbhitsCount,setDbhitsCount] = useState({
      dbhitsCount:0,
      pagecounts:0,
      finished:false
   });
   const [selectedChannelLists,setSelectedChannelLists] = useState({
      selectedChannelLists:[],
      finished:false,
   });
   const [kanrenWords,setkanrenWords] = useState({
      kanrenWords:[],
      finished:false
   });
   const [kanrenPosts,setkanrenPosts] = useState({
      kanrenPosts:[],
      finished:false
   });
   const [ownkanrenWords,setownkanrenWords] = useState({
      kanrenWords:[],
      finished:false
   });

   const getDBHistsChannelCounts = (param) => {
      setDbhitsCount({
         dbhitsCount:0,
         pagecounts:0,
         finished:false
      })
      var type="getDBHistsChannelCounts";
      let postdata = Object.assign({type:type}, param);
      axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
         .then( (res) => {
            // console.log(res.data);
            setDbhitsCount({
               dbhitsCount:res.data.hitsCount,
               pagecounts:Math.ceil(res.data.hitsCount / 10),
               finished:true,
            })
         })
         .catch( (error) => {
            console.log(error);
            setDbhitsCount({
               dbhitsCount:0,
               pagecounts:0,
               finished:true,
            })
      });
   }
   
   const getChannelListsOnePage = (param) => {
      setSelectedChannelLists({
         selectedChannelLists:[],
         finished:false,
      });
      var type="getChannelListsOnePage";
      let postdata = Object.assign({type:type}, param);
      axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
         .then( (res) => {
            console.log(res.data);
            setSelectedChannelLists({
               selectedChannelLists:res.data.channellists,
               finished:true,
            });
         })
         .catch( (error) => {
            console.log(error);
            setSelectedChannelLists({
               selectedChannelLists:[],
               finished:true,
            });
      });
   }
   
   const getChannelKanrenWords = (param) => {
      setkanrenWords({
         kanrenWords:[],
         finished:false
      });
      var type="getChannelKanrenWords";
      let postdata = Object.assign({type:type}, param);
      axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
         .then( (res) => {
            // console.log(res.data);
            var pairs = Object.entries(res.data.wordresult);
            pairs.sort(function(p1, p2){
               var p1Val = p1[1], p2Val = p2[1];
               return p2Val - p1Val;
            })
            setkanrenWords({
               kanrenWords:pairs,
               finished:true
            });
         })
         .catch( (error) => {
            console.log(error);
            setkanrenWords({
               kanrenWords:[],
               finished:true
            });
      });
   }

   const getChannelOwnKanrenWords = (param) => {
      setownkanrenWords({
         kanrenWords:[],
         finished:false
      });
      var type="getChannelOwnKanrenWords";
      let postdata = Object.assign({type:type}, param);
      axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
         .then( (res) => {
            console.log(res.data);
            setownkanrenWords({
               kanrenWords:res.data.datas,
               finished:true
            });
         })
         .catch( (error) => {
            console.log(error);
            setownkanrenWords({
               kanrenWords:[],
               finished:true
            });
      });
   }

   const getChannelKanrenPosts = (param) => {
      setkanrenPosts({
         kanrenPosts:[],
         finished:false
      });
      var type="getChannelKanrenPosts";
      let postdata = Object.assign({type:type}, param);
      axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
         .then( (res) => {
            // console.log(res.data.postresult);
            setkanrenPosts({
               kanrenPosts:res.data.postresult,
               finished:true
            });
         })
         .catch( (error) => {
            console.log(error);
            setkanrenPosts({
               kanrenPosts:[],
               finished:true
            });
      });
   }

   const reflash = (rettemp) => {
      // console.log(rettemp);
      setParams(rettemp);
      getDBHistsChannelCounts(rettemp);
      getChannelListsOnePage(rettemp);

      getChannelKanrenWords(rettemp);
      getChannelOwnKanrenWords(rettemp);
      getChannelKanrenPosts(rettemp);

      setisaddkeyword(false);
   }

   const reflash1 = (rettemp) => {
      // console.log(rettemp);
      setParams(rettemp);
      getChannelListsOnePage(rettemp);
   }

   useEffect(() =>{
      let temp = getWindowParam(params);
      // console.log(temp);
      reflash(temp);
      
   },[]);

   return (
      <Layout 
         // title={"チャンネルランキング"}
         // description={"YouTubeチャンネルランキングを掲載しています"}
         title={"チャンネル"}
         description={"YouTubeチャンネルを掲載しています"}
         // image={}
         uri={pageprops.uri}
         canonicalUri={"/channellist"}
      >
         {/* {console.log(pageprops)} */}
         <Midasi2>チャンネル一覧</Midasi2>

         <Box bg="#ddd" mx="0.75rem" p="1rem">
            <Box>
               キーワード:
               {
                  params.keyword==="" ?
                     "全て"
                  :
                     React.Children.toArray(params.keyword.split(/\s+/).map((val) => {
                        return (
                           val + " "
                        )
                     }))
               }
            </Box>
            <Box>
               人柄・属性:{params.creator}
            </Box>
            <Box>
               内容:{params.genre}
            </Box>

            <Box>再生回数:  {num2ja(params.vtd)} ～ {params.vtu==0?"∞":num2ja(params.vtu)}</Box>
            <Box>登録者数:  {num2ja(params.rtd)} ～ {params.rtu==0?"∞":num2ja(params.rtu)}</Box>
         </Box>

         

         <Box mx="0.75rem" mt="1rem">
            {/* <Select 
               my="0.5rem" 
               icon={<BiSort />} 
               bg="#fff" 
               fontSize="0.9rem" 
               fontWeight="bold"
               onChange={(e) => {
                  reflash1({
                     ...params,
                     viewOrRegist:e.target.value,
                  });
               }}
            >
               <option value="再生回数">再生回数順</option>
               <option value="再生回数s">再生回数順（昇順）</option>
               <option value="登録者数">登録者数順</option>
               <option value="登録者数s">登録者数順（昇順）</option>
            </Select>
            
            <RadioButtonsC5 
               name="selectDuration"
               defaultValue={"全期間"}
               handleclick={(e) => {
                  reflash1({
                     ...params,
                     duration:e,
                  });
               }}
               items={["全期間","日間","週間","月間"]}
            /> */}
         </Box>
         
         <Box bg="#fff" mx="0.75rem">
         
         {/* {console.log(selectedChannelLists)} */}
         {
            selectedChannelLists.finished ?
               selectedChannelLists.selectedChannelLists.length > 0 ?
                  <>
                  <Box pt="0.4rem">
                     {
                     React.Children.toArray(selectedChannelLists.selectedChannelLists.map((val,index)=>{
                        return (
                           <Box borderBottom="1px solid #e0e0e0">
                              <HStack p="0.5rem" pl="1.5rem"
                                 // position="relative"
                                 // _before={{
                                 //    content:attr(data-item),
                                 //    fontSize:"1rem",
                                 //    fontWeight:"bold",
                                 //    color:"#fff",
                                 //    display:"block",
                                 //    position:"absolute",
                                 //    top:"1rem",
                                 //    left:"1rem",
                                 //    p:"0.2rem",
                                 //    bg:"red"
                                 // }}
                              >
                                 <Box boxSize="5rem">
                                    <Image
                                       borderRadius="full"
                                       boxSize="5rem"
                                       src={val.thumbnailUrl}
                                       alt={val.channelTitle}
                                    />
                                 </Box>
                                 <Box pl="1rem" w="calc(100% - 5rem)">
                                    <Link
                                       // as={GatsbyLink} 
                                       href={pageprops.location.origin + "/channeldetail"
                                          + "?ch=" + val.channelId}
                                       fontWeight={"bold"}
                                       fontSize="1.1rem"
                                       lineHeight="1.4rem"
                                       isExternal
                                    >
                                          {val.channelTitle}
                                    </Link>
                                    <Box fontSize="0.8rem" mt="0.5rem">
                                       <Wrap spacing="0" borderBottom="1px #d9d9d9b8 solid">
                                          <WrapItem>チャンネル登録者数 : </WrapItem>
                                          <Spacer />
                                          <WrapItem>{num2ja(val.registCount)}人</WrapItem>
                                       </Wrap>
                                       <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                          <WrapItem>再生回数 : </WrapItem>
                                          <Spacer />
                                          <WrapItem>{num2ja(val.viewsCount)}回</WrapItem>
                                       </Wrap>
                                       {/*
                                          {
                                             "全期間":
                                                <>
                                                <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                                   <WrapItem>日間購読者数 : </WrapItem>
                                                   <Spacer />
                                                   <WrapItem>{num2ja(val.registCountDayDiff)}人</WrapItem>
                                                </Wrap>
                                                <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                                   <WrapItem>週間購読者数 : </WrapItem>
                                                   <Spacer />
                                                   <WrapItem>{num2ja(val.registCountWeekDiff)}人</WrapItem>
                                                </Wrap>
                                                <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                                   <WrapItem>月間購読者数 : </WrapItem>
                                                   <Spacer />
                                                   <WrapItem>{num2ja(val.registCountMonthDiff)}人</WrapItem>
                                                </Wrap>
                                                <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                                   <WrapItem>日間再生回数 : </WrapItem>
                                                   <Spacer />
                                                   <WrapItem>{num2ja(val.viewsCountDayDiff)}回</WrapItem>
                                                </Wrap>
                                                <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                                   <WrapItem>週間再生回数 : </WrapItem>
                                                   <Spacer />
                                                   <WrapItem>{num2ja(val.viewsCountWeekDiff)}回</WrapItem>
                                                </Wrap>
                                                <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                                   <WrapItem>月間再生回数 : </WrapItem>
                                                   <Spacer />
                                                   <WrapItem>{num2ja(val.viewsCountMonthDiff)}回</WrapItem>
                                                </Wrap>
                                                </>,
                                             "日間":
                                                <>
                                                <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                                   <WrapItem>日間購読者数 : </WrapItem>
                                                   <Spacer />
                                                   <WrapItem>{num2ja(val.registCountDayDiff)}人</WrapItem>
                                                </Wrap>
                                                <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                                   <WrapItem>日間再生回数 : </WrapItem>
                                                   <Spacer />
                                                   <WrapItem>{num2ja(val.viewsCountDayDiff)}回</WrapItem>
                                                </Wrap>
                                                <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                                   <WrapItem>日間比較日付 : </WrapItem>
                                                   <Spacer />
                                                   <WrapItem>{val.dayDiffTarget}</WrapItem>
                                                </Wrap>
                                                </>,
                                             "週間":
                                                <>
                                                <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                                   <WrapItem>週間購読者数 : </WrapItem>
                                                   <Spacer />
                                                   <WrapItem>{num2ja(val.registCountWeekDiff)}人</WrapItem>
                                                </Wrap>
                                                <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                                   <WrapItem>週間再生回数 : </WrapItem>
                                                   <Spacer />
                                                   <WrapItem>{num2ja(val.viewsCountWeekDiff)}回</WrapItem>
                                                </Wrap>
                                                <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                                   <WrapItem>週間比較日付 : </WrapItem>
                                                   <Spacer />
                                                   <WrapItem>{val.weekDiffTarget}</WrapItem>
                                                </Wrap>
                                                </>,
                                             "月間":
                                                <>
                                                <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                                   <WrapItem>月間購読者数 : </WrapItem>
                                                   <Spacer />
                                                   <WrapItem>{num2ja(val.registCountMonthDiff)}人</WrapItem>
                                                </Wrap>
                                                <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                                   <WrapItem>月間再生回数 : </WrapItem>
                                                   <Spacer />
                                                   <WrapItem>{num2ja(val.viewsCountMonthDiff)}回</WrapItem>
                                                </Wrap>
                                                <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                                   <WrapItem>月間比較日付 : </WrapItem>
                                                   <Spacer />
                                                   <WrapItem>{val.monthDiffTarget}</WrapItem>
                                                </Wrap>
                                                </>,
                                          }[params.duration]
                                          */
                                       }
                                       {/* <Wrap spacing="0" borderBottom="1px #d9d9d9b8 solid">
                                          <WrapItem>チャンネル登録者数 : </WrapItem>
                                          <Spacer />
                                          <WrapItem>{num2ja(val.registCount)}人</WrapItem>
                                       </Wrap>
                                       <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                          <WrapItem>再生回数 : </WrapItem>
                                          <Spacer />
                                          <WrapItem>{num2ja(val.viewsCount)}回</WrapItem>
                                       </Wrap>
                                       <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                          <WrapItem>日間登録者数 : </WrapItem>
                                          <Spacer />
                                          <WrapItem>{num2ja(val.registCountDayDiff)}人</WrapItem>
                                       </Wrap>
                                       <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                          <WrapItem>週間登録者数 : </WrapItem>
                                          <Spacer />
                                          <WrapItem>{num2ja(val.registCountWeekDiff)}人</WrapItem>
                                       </Wrap>
                                       <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                          <WrapItem>月間登録者数 : </WrapItem>
                                          <Spacer />
                                          <WrapItem>{num2ja(val.registCountMonthDiff)}人</WrapItem>
                                       </Wrap>
                                       <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                          <WrapItem>日間再生回数 : </WrapItem>
                                          <Spacer />
                                          <WrapItem>{num2ja(val.viewsCountDayDiff)}回</WrapItem>
                                       </Wrap>
                                       <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                          <WrapItem>週間再生回数 : </WrapItem>
                                          <Spacer />
                                          <WrapItem>{num2ja(val.viewsCountWeekDiff)}回</WrapItem>
                                       </Wrap>
                                       <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                          <WrapItem>月間再生回数 : </WrapItem>
                                          <Spacer />
                                          <WrapItem>{num2ja(val.viewsCountMonthDiff)}回</WrapItem>
                                       </Wrap>
                                       <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                          <WrapItem>アップ日付 : </WrapItem>
                                          <Spacer />
                                          <WrapItem>{val.updateTime}</WrapItem>
                                       </Wrap>
                                       <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                          <WrapItem>日間比較日付 : </WrapItem>
                                          <Spacer />
                                          <WrapItem>{val.dayDiffTarget}</WrapItem>
                                       </Wrap>
                                       <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                          <WrapItem>週間比較日付 : </WrapItem>
                                          <Spacer />
                                          <WrapItem>{val.weekDiffTarget}</WrapItem>
                                       </Wrap>
                                       <Wrap spacing="0" mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">
                                          <WrapItem>月間比較日付 : </WrapItem>
                                          <Spacer />
                                          <WrapItem>{val.monthDiffTarget}</WrapItem>
                                       </Wrap> */}
                                    </Box>
                                 </Box>
                              </HStack>
                           </Box>
                        )
                     }))
                     }

                  </Box>

                  {/* <Pagenavi1 
                     params={params} 
                     pagecounts={dbhitsCount.pagecounts} 
                     pager={pager}
                     setPager={setPager}
                     handleclick={reflash1} 
                  /> */}
                  <Pagenavi2 
                     params={params} 
                     pagecounts={dbhitsCount.pagecounts} 
                     pager={pager}
                     setPager={setPager}
                     handleclick={reflash1} 
                  />

                  <Box mt="-2rem" pb="1rem" pr="1rem" fontSize="0.8rem" textAlign="right">
                     {dbhitsCount.finished ? 
                        dbhitsCount.dbhitsCount
                     :
                        <Spinner size="0.8rem"/>
                     }
                  </Box>

                  </>
               :
                  <Box>
                     <Text>該当するチャンネルはありません</Text>
                  </Box>
            :
               <>
               <Box pt="0.4rem">
                  {
                  React.Children.toArray([...Array(10)].map(() => {
                     return (
                        <Box borderBottom="1px solid #e0e0e0">
                           <HStack p="0.5rem" pl="1.5rem">
                              <Box boxSize="5rem">
                              <Skeleton
                                 as={Image}
                                 borderRadius="full"
                                 boxSize="5rem"
                              />
                              </Box>
                              <Box pl="1rem" w="calc(100% - 5rem)">
                                 <Skeleton height="1.4rem">SkeletonText</Skeleton>
                                 <Box fontSize="0.8rem" mt="0.5rem">
                                    <Skeleton  borderBottom="1px #d9d9d9b8 solid">SkeletonText</Skeleton>
                                    <Skeleton mt="0.3rem" borderBottom="1px #d9d9d9b8 solid">SkeletonText</Skeleton>
                                 </Box>
                              </Box>
                           </HStack>
                        </Box>
                     )
                  }))
                  }
               </Box>
               <Center p="4rem">
                  {/* ページナビ部分 */}
               </Center>
               </>
         }
         
         </Box>
         

         {ownkanrenWords.finished ? 
            ownkanrenWords.kanrenWords.length > 0 ?
               <>
               {React.Children.toArray(ownkanrenWords.kanrenWords.map(val=>{
                  return (
                  <>
                  {val.adimageUrl !== "" ? 
                     <Box m="1rem">
                        <Link as={GatsbyLink} to={val.adUrl}>
                           <AspectRatio w="100%"  ratio={728 / 90}>
                              <Image 
                                 w="100%" 
                                 verticalAlign="bottom" 
                                 src={val.adimageUrl}
                                 fallbackSrc="https://via.placeholder.com/728x90" 
                              />
                           </AspectRatio>
                        </Link>
                     </Box>
                  :
                     ""
                  }
                  </>
                  )
               }))}
               </>
            :
            ""
         :
         ""
         }

         <Box bg="#ddd" mx="0.75rem" p="1rem" mt="1rem">
            <Box mt="1rem">
               <Wrap>
                  <WrapItem>
                     <Switch 
                        onChange={e => setisaddkeyword(e.target.checked)} 
                     />
                  </WrapItem>
                  <WrapItem>
                     <Text alignSelf="center" fontWeight="bold">キーワードを追加</Text>
                  </WrapItem>
               </Wrap>

               {ownkanrenWords.finished ? 
                  ownkanrenWords.kanrenWords.length > 0 ?
                     <>
                     <Midasi3 mb="0rem">ピックアップ</Midasi3>
                     
                        {React.Children.toArray(ownkanrenWords.kanrenWords.map(val=>{
                           return (
                           <>
                           {/*                            
                           <Box>
                              {val.discription}
                           </Box>
                           <Box>
                              <Image src={val.imageUrl} alt="Segun Adebayo" />
                           </Box> */}
                           {React.Children.toArray(val.wordlist.split(",").map(word=>{
                              let key = "";
                              if(params.keyword === ""){
                                 key = word;
                              }else{
                                 if(isaddkeyword){
                                    key = params.keyword + " " + word;
                                 }else{
                                    key = word;
                                 }
                              }
                              return (
                                 <Button 
                                    colorScheme="blue"
                                    borderRadius="0.5rem"
                                    fontSize="0.7rem"
                                    mr="0.6rem"
                                    mt="0.6rem"
                                    px="0.5rem"
                                    height="1.5rem"
                                    onClick={() => {
                                       window.scrollTo(0, 0);
                                       reflash({
                                          ...params,
                                          keyword:key
                                       });
                                    }}
                                 >
                                    {word}
                                 </Button>
                              )
                           }))}
                           </>
                           )
                        }))
                        }
                     </>
                  :
                  ""
               :
               ""
               }

               
               {kanrenWords.finished ? 
                  kanrenWords.kanrenWords.length > 0 ?
                     <>
                     <Midasi3 mb="0rem">関連キーワード</Midasi3>
                        {React.Children.toArray(kanrenWords.kanrenWords.map(val=>{
                           let key = "";
                           if(params.keyword === ""){
                              key = val[0];
                           }else{
                              if(isaddkeyword){
                                 key = params.keyword + " " + val[0];
                              }else{
                                 key = val[0];
                              }
                           }
                           return (
                              <Tooltip hasArrow label={val[1]}>
                                 <Button 
                                    colorScheme="facebook"
                                    borderRadius="0.5rem"
                                    fontSize="0.7rem"
                                    mr="0.6rem"
                                    mt="0.6rem"
                                    px="0.5rem"
                                    height="1.5rem"
                                    onClick={() => {
                                       window.scrollTo(0, 0);
                                       reflash({
                                          ...params,
                                          keyword:key
                                       });
                                    }}
                                 >
                                    {val[0]}
                                 </Button>
                              </Tooltip>
                           )
                        }))}
                     
                     </>
                  :
                  ""
               :
                  <Box mt="1rem">
                     <Spinner />
                  </Box>
               }
            
               {/* <Box m="0.3rem" fontSize="0.7rem" textAlign="right">
                  {dbhitsCount.finished ? 
                     dbhitsCount.dbhitsCount
                  :
                     <Spinner size="0.7rem"/>
                  }
               </Box> */}
            </Box>
         </Box>

         
         <Midasi2>関連ニュース</Midasi2>
         <Box px="0.75rem" display="flex" flexWrap="wrap">
            {kanrenPosts.finished ? 
               kanrenPosts.kanrenPosts.data ?
                  React.Children.toArray(kanrenPosts.kanrenPosts.data.posts.nodes.map((node,index) => {
                  
                     return (
                        <Box bg="#fff" mb="1rem" w="50%" verticalAlign="top" boxSizing="border-box" 
                           borderRight={(index+1)%2 === 0 ?"none":"0.375rem solid #eee"}
                           borderLeft={(index+1)%2 === 0 ?"0.375rem solid #eee":"none"}
                        >
                           <Link as={GatsbyLink} to={node.uri}>
                              <Image 
                                 w="100%" 
                                 verticalAlign="bottom" 
                                 src={node.featuredImage ? node.featuredImage.node.mediaItemUrl : ""}
                                 fallbackSrc="https://via.placeholder.com/337x190" 
                              />
                              <Text
                                 py="1.25rem"
                                 px="1rem"
                                 bg="#fff"
                                 fontSize="1.0rem"
                                 fontWeight="bold"
                                 lineHeight="1.5rem"
                                 boxSizing="border-box"
                                 wordBreak="break-all"
                              >
                                 {node.title}
                              </Text>
                           </Link>
                        </Box>
                     )
                  }))
               :
                  <Box>
                     <Text>該当する関連ニュースはありません</Text>
                  </Box>
            :
               ""
            }

            <Text w="100%"
               rounded="sm"
               fontSize="1.3rem"
               fontWeight="bold"
               lineHeight="4rem"
               textAlign="center"
               color="#fff"
               bg="linkedin.800"
               position="relative"
               _before={{
                  content:'""',
                  display:"block",
                  position:"absolute",
                  top:"1.6rem",
                  right:"2rem",
                  w:"1rem",
                  h:"1rem",
                  borderTop:"0.2rem solid #fff",
                  borderRight:"0.2rem solid #fff",
                  transform:"rotate(45deg)"
               }}
               as={GatsbyLink}
               to="/category/news/1"
            >もっと見る</Text>
         </Box> 
      </Layout>
   )
}
export default ChannelList;